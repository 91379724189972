<template>
    <v-container class="p-relative">
        <v-row>
            <v-col cols="12" class="py-0">
                <AdminHeader
                    :registryTitle="$t('sessionLogs:login')"
                    :registryUrl="'users-activity'"
                    @clearSearch="clearTable"
                />
                <v-stepper v-model="stepper" class="my-5">
                    <v-stepper-header :hidden="true">
                        <v-stepper-step editable step="1">
                            {{ $t('sessionLogs:selectDate') }}
                        </v-stepper-step>

                        <v-divider></v-divider>

                        <v-stepper-step :editable="stepper > 1" step="2">
                            {{ $t('sessionLogs:selectUser') }}
                        </v-stepper-step>

                        <v-divider></v-divider>

                        <v-stepper-step step="3">
                            {{ $t('sessionLogs:userActivity') }}
                        </v-stepper-step>
                    </v-stepper-header>
                    <v-stepper-items>
                        <v-stepper-content step="1"
                            ><v-sheet tile height="54" class="d-flex"
                                ><v-btn
                                    icon
                                    class="ma-2"
                                    @click="$refs.calendar.prev()"
                                    ><v-icon>mdi-chevron-left</v-icon></v-btn
                                ><v-btn
                                    icon
                                    class="ma-2"
                                    @click="$refs.calendar.next()"
                                    ><v-icon>mdi-chevron-right</v-icon></v-btn
                                ></v-sheet
                            >
                            <v-sheet height="calc(-334px + 100vh)">
                                <v-calendar
                                    v-model="calendar"
                                    ref="calendar"
                                    :weekdays="[0, 1, 2, 3, 4, 5, 6]"
                                    :type="'month'"
                                    @click:date="selectDay"
                                ></v-calendar> </v-sheet></v-stepper-content
                        ><v-stepper-content step="2" class="pa-0">
                            <Table
                                :headers="headers"
                                height="calc(100vh-360px)"
                                :items="getUsersActivityTable.items"
                                :length="getUsersActivityTable.pages"
                                :page="getUsersActivityTable.page"
                                :defaultFields="[]"
                                @search="setSearch"
                                class="usersTable"
                                @change-page="setPage"
                            >
                                <Columns
                                    v-on:user-selected="statistics"
                                    slot="columns"
                                    :items="getUsersActivityTable.items"
                                />
                            </Table> </v-stepper-content
                        ><v-stepper-content step="3"
                            ><v-carousel
                                v-model="carousel"
                                light
                                height="calc(-280px + 100vh)"
                                hide-delimiter-background
                                hide-delimiters
                                ><v-carousel-item eager
                                    ><v-sheet height="760">
                                        <canvas id="activityChart"></canvas
                                        ><br /><v-progress-circular
                                            style="margin-right: 2rem;"
                                            :rotate="360"
                                            :size="90"
                                            :width="8"
                                            :value="maxPercent"
                                            color="teal"
                                        >
                                            {{
                                                maxPercent
                                            }}% </v-progress-circular
                                        >{{ $t(`sessionLogs:cmpToMax`)
                                        }}<br /><v-progress-circular
                                            style="margin-right: 2rem;"
                                            :rotate="360"
                                            :size="90"
                                            :width="8"
                                            :value="avgPercent"
                                            color="orange"
                                        >
                                            {{
                                                avgPercent
                                            }}% </v-progress-circular
                                        >{{
                                            $t(`sessionLogs:cmpToAvg`)
                                        }}</v-sheet
                                    ></v-carousel-item
                                ><v-carousel-item eager
                                    ><v-sheet height="760"
                                        ><canvas id="inactivityChart"></canvas
                                        ><br /><v-progress-circular
                                            style="margin-right: 2rem;"
                                            :rotate="360"
                                            :size="90"
                                            :width="8"
                                            :value="awayTimePercent"
                                            color="red"
                                        >
                                            {{
                                                awayTimePercent
                                            }}% </v-progress-circular
                                        >{{
                                            $t(`sessionLogs:awayTime`)
                                        }}</v-sheet
                                    ></v-carousel-item
                                ></v-carousel
                            ></v-stepper-content
                        ></v-stepper-items
                    >
                </v-stepper>
                <Modal
                    :title="$t('sessionLogs:accountCancel')"
                    :open="open"
                    @close="closeModal"
                    :width="'1200'"
                    :height="'750'"
                >
                    <AEContent slot="AEContent" />
                </Modal>
                <div class="d-flex justify-end buttons--action">
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                fab
                                dark
                                v-on="on"
                                small
                                @click="openAccountModal"
                                class="buttons--add margin--1"
                            >
                                <v-icon small>mdi-account-cancel</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('sessionLogs:showAccountCancel') }}</span>
                    </v-tooltip>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Buttons from './../../../components/Admin/SessionLogs/Modal/Buttons'
import AEContent from './../../../components/Admin/SessionLogs/Modal/Content'
import Columns from './../../../components/Admin/SessionLogs/Table/Columns'
import store from './../../../store/index'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Chart from 'chart.js'
import moment from 'moment'

export default {
    components: {
        Columns,
        AEContent,
        Buttons,
    },
    methods: {
        ...mapActions([
            'fetchLoginTable',
            'fetchUserActivityData',
            'fetchUsersManagment',
        ]),
        ...mapMutations(['setLoginTable']),
        closeModal() {
            this.open = false
        },
        clearTable() {
            this.stepper = 1
            this.calendar = ''
        },
        setSearch(search) {
            this.setLoginTable({ search, page: 1 })
            this.fetchLoginTable({ date: this.date })
        },
        setPage(page) {
            this.setLoginTable({ page })
            this.fetchLoginTable({ date: this.date })
        },
        async openAccountModal() {
            await this.fetchUsersManagment()
            this.open = true
        },
        async selectDay(e) {
            this.date = e.date
            await this.fetchLoginTable({ date: e.date })
            this.stepper = 2
        },
        async statistics(item) {
            this.destroyAll()
            await this.fetchUserActivityData({
                id: item.user._id,
                date: this.date,
            })
            this.user = item
            this.setChart()
            this.setInactivityChart()
            this.carousel = 0
            this.stepper = 3
        },
        setChart() {
            let minHour = new Date(this.user.minDate).getHours()
            let maxHour = new Date(this.user.maxDate).getHours()
            let labels = this.getLabelsFromHourRange(minHour, maxHour)
            let map = this.groupBy(
                []
                    .concat(
                        ...Object.values(
                            this.getUserActivityData.methodsActivity
                        )
                    )
                    .map(date => new Date(date).getHours()),
                x => x
            )
            let data = this.valuesFromMap(minHour, maxHour, map)
            this.maxPercent =
                (data.filter(Number).reduce((a, b) => a + b) /
                    this.getActivityStatistics.highestActivity) *
                100
            this.maxPercent = Number(this.maxPercent).toFixed(0)
            this.avgPercent =
                (data.filter(Number).reduce((a, b) => a + b) /
                    this.getActivityStatistics.averageActivity) *
                100
            this.avgPercent = Number(this.avgPercent).toFixed(0)
            let ctx = document.getElementById('activityChart').getContext('2d')
            this.chart1 = new Chart(ctx, {
                type: 'line',
                data: {
                    labels: labels,
                    datasets: [
                        {
                            label: ['Activity line'],
                            data: [0, ...data, 0],
                            backgroundColor: ['rgba(255, 99, 132, 0)'],
                            borderColor: ['rgba(54, 162, 235, 1)'],
                            borderWidth: 2,
                            lineTension: 0,
                        },
                        {
                            label: ['Activity bar'],
                            data: [0, ...data, 0],
                            borderWidth: 2,
                            type: 'bar',
                        },
                    ],
                },
                options: {
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    callback: function (value, index, values) {
                                        return ''
                                    },
                                    max: this.getActivityStatistics.bestHourly,
                                },
                            },
                        ],
                        xAxes: [
                            {
                                gridLines: { offsetGridLines: true },
                            },
                        ],
                    },
                    events: [],
                },
            })
        },
        setInactivityChart() {
            this.animation = true
            let minHour = new Date(this.user.minDate).getHours()
            let maxHour = new Date(this.user.maxDate).getHours()
            let labels = this.getLabelsFromHourRange(minHour, maxHour)
            let ctx = document
                .getElementById('inactivityChart')
                .getContext('2d')
            labels.shift()
            this.chart2 = new Chart(ctx, {
                type: 'line',
                data: {
                    labels: labels,
                    datasets: [
                        {
                            label: ['inactivity periods'],
                            data: [],
                            backgroundColor: ['rgba(255, 99, 132, 0)'],
                            borderColor: ['rgba(252, 13, 32, 1)'],
                            borderWidth: 2,
                            lineTension: 0,
                        },
                    ],
                },
                options: {
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    callback: function (value, index, values) {
                                        return ''
                                    },
                                    max: this.getActivityStatistics.bestHourly,
                                },
                            },
                        ],
                    },
                    events: [],
                },
            })
            this.chart2.stop()
            let calc = this.preCalc()
            this.evalInactivityPercent(calc, minHour, maxHour)
            function draw() {
                if (ctx.canvas.width)
                    this.calcIntervals(
                        ctx,
                        calc,
                        labels[labels.length - 1],
                        labels.length,
                        labels[0]
                    )

                ctx.strokeStyle = 'rgba(252, 13, 32, 1)'
                ctx.lineWidth = 32
                ctx.beginPath()
                for (let interval of this.intervals) {
                    ctx.moveTo(interval.x1, ctx.canvas.height / 2)
                    ctx.lineTo(
                        interval.x2 == 'end'
                            ? ctx.canvas.width - 18
                            : interval.x2,
                        ctx.canvas.height / 2
                    )
                    ctx.stroke()
                }
                if (this.animation)
                    requestAnimationFrame(() => draw.apply(this))
                else return
            }
            draw.apply(this)
        },
        evalInactivityPercent(calc, min, max) {
            max++
            let hours = max - min
            let minutes = calc.map(c => c.duration)
            if (minutes.length > 0) minutes = minutes.reduce((a, b) => a + b)
            else minutes = 0
            minutes +=
                moment.duration(1, 'hours').asMinutes() -
                moment(this.user.maxDate).minutes() +
                moment(this.user.minDate).minutes()

            // let offMax =
            //     moment.duration(max, 'hours').asMinutes() -
            //     moment(this.user.maxDate).minutes()
            // let offMin =
            //     moment(this.user.minDate).minutes() -
            //     moment.duration(min, 'hours').asMinutes()
            this.awayTimePercent = Number(
                (minutes / (hours * 60)) * 100
            ).toFixed(0)
        },
        preCalc() {
            let intervalTime = 5 // minutes
            let sortedActivities = Object.values(
                this.getUserActivityData.methodsActivity
            )
                .flat()
                .map(o => (o = moment(o)))
                .sort((a, b) => b - a)
                .reverse()
            let filtered = []
            for (let [index, value] of sortedActivities.entries()) {
                if (sortedActivities[index + 1]) {
                    let duration = moment
                        .duration(
                            sortedActivities[index + 1].diff(
                                sortedActivities[index]
                            )
                        )
                        .asMinutes()
                    if (duration >= intervalTime)
                        filtered.push({
                            stop: sortedActivities[index + 1],
                            start: sortedActivities[index],
                            duration: duration,
                        })
                }
            }
            return filtered
        },

        calcIntervals(ctx, calc, hours, len, minH) {
            let intervals = []
            for (let c of calc) {
                let x1 = this.timeToPos(
                    c.start,
                    ctx.canvas.width - 36,
                    hours,
                    len,
                    minH
                )
                let x2 = this.timeToPos(
                    c.stop,
                    ctx.canvas.width - 36,
                    hours,
                    len,
                    minH
                )
                intervals.push({ x1: x1, x2: x2 })
            }
            intervals.push(
                {
                    x1: 18,
                    x2: this.timeToPos(
                        moment(this.user.minDate),
                        ctx.canvas.width - 36,
                        hours,
                        len,
                        minH
                    ),
                },
                {
                    x1: this.timeToPos(
                        moment(this.user.maxDate),
                        ctx.canvas.width - 36,
                        hours,
                        len,
                        minH
                    ),
                    x2: ctx.canvas.width - 18,
                }
            )
            this.intervals = intervals
        },
        timeToPos(time, canvasLength, hoursCount, len, minH) {
            let oneBlockLen = canvasLength / (len - 1)
            hoursCount = hoursCount.split(':')[0]
            minH = minH.split(':')[0]
            if (hoursCount.startsWith('0'))
                hoursCount = Number(hoursCount.substring(1))
            if (minH.startsWith('0')) minH = Number(minH.substring(1))
            let min = moment.duration(minH, 'hours').asMinutes()
            let max = moment.duration(hoursCount, 'hours').asMinutes()
            let x = time.diff(moment(time).startOf('day'), 'minutes')
            return this.mapRange(x, min, max, 18, canvasLength + 18)
        },
        mapRange(value, x1, y1, x2, y2) {
            return ((value - x1) * (y2 - x2)) / (y1 - x1) + x2
        },
        getLabelsFromHourRange(min, max) {
            let result = []
            for (let i = min - 1; i <= max + 1; i++) {
                if (Number(i) < 10) i = `0${i}`
                result.push(`${i}:00`)
            }
            return result
        },
        groupBy(list, keyGetter) {
            const map = new Map()
            list.forEach(item => {
                const key = keyGetter(item)
                let val = map.get(key)
                if (!val) {
                    map.set(key, 1)
                } else {
                    val++
                    map.set(key, val)
                }
            })
            return map
        },
        valuesFromMap(min, max, map) {
            let result = []
            for (let i = min; i <= max; i++) {
                result.push(map.get(i))
            }
            return result
        },
        destroyAll() {
            this.intervals = []
            this.animation = false
            if (this.chart1) this.chart1.stop()
            if (this.chart2) this.chart2.stop()
        },
    },
    computed: {
        ...mapGetters([
            'getUsersActivityTable',
            'getActivityStatistics',
            'getUserActivityData',
        ]),
    },

    data() {
        return {
            animation: true,
            awayTimePercent: null,
            intervals: [],
            carousel: 0,
            user: null,
            maxPercent: null,
            avgPercent: null,
            date: null,
            open: false,
            calendar: null,
            stepper: 1,
            action: '',
            headers: [
                {
                    text: this.$t('sessionLogs:users'),
                    value: 'user',
                    width: '30%',
                },

                {
                    text: this.$t('sessionLogs:activeFrom'),
                    value: 'minDate',
                    width: '25%',
                    sortable: false,
                },
                {
                    text: this.$t('sessionLogs:activeTo'),
                    value: 'maxDate',
                    width: '25%',
                    sortable: false,
                },
                {
                    text: '',
                    value: 'arrow',
                    align: 'right',
                    width: '6%',
                    sortable: false,
                },
            ],
        }
    },
    beforeRouteLeave(to, from, next) {
        this.destroyAll()
        next()
    },
}
</script>
<style lang="sass" scoped>
.usersTable
    height: calc(-240px + 100vh) !important
</style>
