<template>
    <v-container class="pt-0">
        <v-row>
            <v-col cols="12">
                <Table
                    :page="getUsersLoginTable.page"
                    @search="setSearch"
                    @change-page="setPage"
                    @sort="setSort"
                    :height="'500px'"
                    :length="getUsersLoginTable.pages"
                    :items="getUsersLoginTable.items"
                    :defaultFields="getUsersLoginSearchField"
                    :searchOption="false"
                    :headers="headers"
                    class="sessionLogs__table"
                >
                    <Columns slot="columns" :items="getUsersLoginTable.items" />
                </Table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Columns from './Columns'
export default {
    data() {
        return {
            step: 1,
            headers: [
                {
                    text: this.$t('sessionLogs:users'),
                    value: 'name',
                    width: '40%',
                },
                {
                    text: this.$t('sessionLogs:loginAttempts'),
                    value: 'loginTries',
                    width: '15%',
                },
                {
                    text: this.$t('sessionLogs:isBlocked'),
                    value: 'isBanned',
                    width: '15%',
                },
                {
                    text: this.$t('sessionLogs:attemptData'),
                    value: 'lastLoginTry',
                    width: '15%',
                },
                {
                    text: '',
                    value: 'actions',
                    width: '15%',
                    sortable: false,
                },
            ],
        }
    },
    computed: {
        ...mapGetters([
            'getUsersActivityErrors',
            'getUsersLoginTable',
            'getUsersLoginSearchField',
        ]),
    },
    components: {
        Columns,
    },
    methods: {
        ...mapActions(['chooseOperation', 'fetchUsersManagment']),
        ...mapMutations(['setUsersLoginTable', 'setSearchLoginTable']),
        openConfirmModal(id) {
            this.removeItemID = id
            this.openConfirm = true
        },
        closeConfirmModal() {
            this.openConfirm = false
        },
        async remove() {
            let result = await this.deleteRole({ id: this.removeItemID })
            if (result) this.openConfirm = false
        },
        setSearch(search) {
            this.setUsersLoginTable({ search, page: 1 })
            this.fetchUsersManagment()
        },
        setPage(page) {
            this.setUsersLoginTable({ page })
            this.fetchUsersManagment()
        },
        setSort(sort) {
            this.setUsersLoginTable({ sort, page: 1 })
            this.fetchUsersManagment()
        },
    },
}
</script>

<style scoped>
.list {
    overflow-y: scroll;
}
</style>
