<template>
    <tbody>
        <tr v-for="(item, index) in items" :key="index">
            <td class="truncate">
                {{ `${item.user.name} ${item.user.lastname}` }}
            </td>
            <td class="truncate">
                <DoubleLine
                    :secondLine="$moment(item.minDate).format(`DD-MM-YYYY`)"
                    :firtstLine="$moment(item.minDate).format(`HH:mm`)"
                />
            </td>
            <td class="truncate">
                <DoubleLine
                    :secondLine="$moment(item.maxDate).format(`DD-MM-YYYY`)"
                    :firtstLine="$moment(item.maxDate).format(`HH:mm`)"
                />
            </td>
            <td class="truncate">
                <v-btn small @click="$emit('user-selected', item)" icon>
                    <v-icon color="secondary" small
                        >mdi-arrow-right-thick</v-icon
                    >
                </v-btn>
            </td>
        </tr>
    </tbody>
</template>
<script>
import { mapActions } from 'vuex'

export default {
    props: ['items'],
}
</script>
