<template>
    <tbody>
        <tr v-for="(item, index) in items" :key="index">
            <td class="truncate">{{ `${item.name} ${item.lastname}` }}</td>
            <td class="truncate">{{ item.loginTries }}</td>
            <td class="truncate">
                {{ item.isBanned ? $t('global:yes') : $t('global:no') }}
            </td>

            <td class="truncate">
                <DoubleLine
                    :firtstLine="
                        item.lastLoginTry
                            ? $moment(item.lastLoginTry).format(`DD-MM-YYYY`)
                            : '...'
                    "
                    :secondLine="
                        item.lastLoginTry
                            ? $moment(item.lastLoginTry).format(`HH:mm`)
                            : '...'
                    "
                />
            </td>
            <td class="truncate">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="setIsBanned(item)" icon small v-on="on">
                            <v-icon
                                size="20"
                                :color="item.isBanned ? 'success' : 'errors'"
                                >mdi-account-lock</v-icon
                            >
                        </v-btn>
                    </template>
                    <span>{{
                        item.isBanned
                            ? $t('sessionLogs:unblockUser')
                            : $t('sessionLogs:blockUser')
                    }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="resetTries(item)" icon small v-on="on">
                            <v-icon size="20" color="textGrey"
                                >mdi-lock-reset</v-icon
                            >
                        </v-btn>
                    </template>
                    <span>{{ $t('sessionLogs:resetAttempts') }}</span>
                </v-tooltip>
            </td>
        </tr>
    </tbody>
</template>
<script>
import { mapActions } from 'vuex'

export default {
    props: ['items'],
    methods: {
        ...mapActions(['updateUserManagment']),
        async setIsBanned(user) {
            user.isBanned = !user.isBanned
            await this.updateUserManagment(user)
        },
        async resetTries(user) {
            user.loginTries = 0
            await this.updateUserManagment(user)
        },
    },
}
</script>
